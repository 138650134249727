import { get, post } from '../http.js'
const module = {
    store_page: (params) => {
        return get('/manageStore/page', params)
    },
    store_list: (params) => {
        return get('/store/list', params)
    },
    department_select: (params) => get('/depart/select', params),
    store_update: (params) => {
        return post('/manageStore/update', params)
    },
    store_updateStatus: (params) => post('/manageStore/updateStatus', params),

    // allowPay
    allowPay_bind: (params) => post('/allowpaylist/bind', params),
    allowPay_list: (params) => get('/allowpaylist/info', params),
}

export default module
