import router from '@/router'
import store from '@/store'
import axios from 'axios'
// import { Message, Loading } from 'element-ui'
import { Message } from 'element-ui'

const url_base =
    // ? 'http://192.168.124.19:8085/depart'
    process.env.NODE_ENV === 'development'
        ? 'http://192.168.124.6:9095/depart'
        : 'https://ghlxapi.dynews.zj.cn/depart/'
const service = axios.create({
    baseURL: url_base,
    // timeout: 30000,
    // headers: {
    //     'Content-Type': 'application/json',
    // },
    // headers: {
    //     'Content-Type': 'application/x-www-form-urlencoded',
    // },
})

export function get(url, params) {
    return new Promise((resolve, reject) => {
        service
            .get(url, {
                params: params,
            })
            .then((res) => {
                resolve(res)
            })
            .catch((err) => {
                // console.log(' get err:', err)
                reject(err)
            })
    })
}

export function post(url, data, contentType) {
    console.log(contentType ? contentType : 'application/json')
    // {
    //             headers: {
    //                 'Content-Type': contentType
    //                     ? contentType
    //                     : 'application/json',
    //             },
    //         }
    return new Promise((resolve, reject) => {
        service
            .post(url, data)
            // .then((res) => checkLogin(res))
            .then((res) => {
                resolve(res)
            })
            .catch((err) => {
                // console.log(' post err res:', err)
                reject(err)
            })
    })
    // return service.post(url, data)
}

service.interceptors.request.use(
    (config) => {
        if (store.state.user.token != '') {
            config.headers['token'] = store.state.user.token
            // config.headers['token'] = sessionStorage.getItem('token')
        }
        // console.log('request config:', config)
        return config
    },
    (error) => {
        // Loading.service().close()
        Message({
            message: error || 'Error',
            type: 'error',
            duration: 3 * 1000,
        })
    }
)

service.interceptors.response.use(
    (response) => {
        let res = response.data
        if (res.code === 200 || res.code === '200') {
            return res.result ?? res
        } else if (res.code === 502 || res.code === '502') {
            // Loading.service().close()
            Message({
                duration: 1000,
                type: 'error',
                message: '登录过期,请重新登录',
                onClose: async () => {
                    store.commit('logout')
                    router.push('/login')
                },
            })
        } else {
            // Loading.service().close()
            console.log('response error:', res)
            Message({
                message: res.message || res.msg || 'Error',
                type: 'error',
                duration: 3 * 1000,
            })
            return Promise.reject(new Error(res.message || res.msg || 'Error'))
        }
    },
    (error) => {
        // Loading.service().close()
        Message({
            message: error || 'Error',
            type: 'error',
            duration: 3 * 1000,
        })
    }
)

export default service
