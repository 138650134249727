import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// ElementUI
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI)

// 样式
import '@/style/common.less'
import '@/style/login.less'
import '@/style/layout.less'

// axios
import api from '@/api/api'
Vue.prototype.$api = api

//路由守卫
router.beforeEach((to, from, next) => {
    let token = store.state.user.token
    if (token == '' && to.path !== '/login') {
        next({ path: '/login' })
    } else {
        next()
    }
})

Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: (h) => h(App),
    created() {
        store.commit('createRouter', router)
    },
}).$mount('#app')
