import { get, post } from '../http.js'

const module = {
    department_info: (params) => {
        return get('/depart/info', params)
    },
    departCategory_select: (params) => {
        return get('/departCategory/select', params)
    },
    department_update: (params) => {
        return post('/depart/update', params)
    },
    apply_page: (params) => {
        return post('/departApply/page', params)
    },
    apply_list: (params) => {
        return post('/departApply/list', params)
    },
    apply_updateStatus: (params) => {
        return post('/departApply/updateStatus', params)
    },
}

export default module
