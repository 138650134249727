import { get, post } from './http.js'
import department from './module/department.js'
import store from './module/store.js'

const api = {
    department,
    store,
}
// ? 'http://192.168.124.20:8990/hainingBackstagePlatform/'
// 上传
api.upload = (params) =>
    post('https://ghlxapi.dynews.zj.cn/manager/fileUpload/picUpload', params)

// 登录
api.login = (params) => post('/account/login', params)
api.sendSms = (params) => post('/account/sendSms', params)
api.logout = (params) => post('/account/logout', params)
api.resetPassword = (params) => post('/account/resetPassword', params)

// user
api.user_importExcel = (params) => post('/user/importExcel', params)
api.user_page = (params) => get('/user/page', params)
api.user_list = (params) => get('/user/list', params)
api.user_select = (params) => get('/user/select', params)
api.user_resetPWD = (params) => post('/user/resetPayPassword', params)
api.user_save = (params) => post('/user/save', params)
api.user_update = (params) => post('/user/update', params)
api.user_dimission = (params) => get('/user/dimission', params)
// 单位商家绑定
api.allowPay_list = (params) => get('/allowpaylist/info', params)
api.allowPay_bind = (params) => post('/allowpaylist/bind', params)
// store
api.store_info = (params) => get('/store/info', params)
api.store_select = (params) => get('/store/select', params)
// 站内信
api.message_page = (params) => get('/message/page', params)
api.message_sendRecordPage = (params) => get('/message/sendRecordPage', params)
api.message_save = (params) => post('/message/save', params)
api.message_update = (params) => post('/message/update', params)
api.message_send = (params) => post('/message/send', params)

api.order_page = (params) => get('/order/page', params)

api.rechargelist_item_select = (params) =>
    get('/rechargeitemlist/select', params)
api.rechargelist_page = (params) => get('/rechargelist/page', params)
api.rechargelist_save = (params) => post('/rechargelist/save', params)
api.rechargelist_update = (params) => post('/rechargelist/update', params)
api.rechargelist_startRecharge = (params) =>
    post('/rechargelist/startRecharge', params)
api.rechargelist_updateRechargeOrderStatus = (params) =>
    post('/rechargelist/updateRechargeOrderStatus', params)
export default api
