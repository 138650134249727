import Vue from 'vue'
import VueRouter from 'vue-router'

//避免到当前位置的冗余导航 简单来说就是重复触发了同一个路由
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch((err) => err)
}

Vue.use(VueRouter)

const routes = [
    {
        path: '/login',
        component: () => import('../views/Login.vue'),
    },
]

const router = new VueRouter({
    routes,
})

export default router
