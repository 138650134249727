export default {
    state: {
        token: sessionStorage.getItem('token')
            ? sessionStorage.getItem('token')
            : '',
        menuList: sessionStorage.getItem('menuList')
            ? JSON.parse(sessionStorage.getItem('menuList'))
            : [],
        menuCurrent: sessionStorage.getItem('menuCurrent')
            ? JSON.parse(sessionStorage.getItem('menuCurrent'))
            : [],
        userInfo: sessionStorage.getItem('userInfo')
            ? JSON.parse(sessionStorage.getItem('userInfo'))
            : [],
    },
    mutations: {
        // 登录
        login(state, res) {
            // console.log(res)
            state.token = res.token
            sessionStorage.setItem('token', state.token)

            state.userInfo = res.userInfo
            sessionStorage.setItem('userInfo', JSON.stringify(state.userInfo))

            state.menuList = res.menuList
            sessionStorage.setItem('menuList', JSON.stringify(state.menuList))

            state.menuCurrent = res.menuCurrent
            sessionStorage.setItem(
                'menuCurrent',
                JSON.stringify(state.menuCurrent)
            )
        },
        updateMenu(state, menuList) {
            console.log('updateMenu mutation', menuList)
            state.menuList = menuList
            sessionStorage.setItem('menuList', JSON.stringify(state.menuList))
        },
        // 退出
        logout() {
            sessionStorage.removeItem('token')
            sessionStorage.removeItem('userInfo')
            sessionStorage.removeItem('menuList')
            sessionStorage.removeItem('menuCurrent')
        },
        // 生成动态路由
        createRouter(state, router) {
            let obj = {
                path: '/',
                component: () => import('../views/Layout.vue'),
                children: [
                    {
                        path: '/',
                        component: () => import('../views/Home.vue'),
                    },
                ],
            }
            if (state.menuList.length > 0) {
                // 一级路由
                state.menuList.forEach((item) => {
                    obj.children.push({
                        path: '/' + item.url,
                        component: () =>
                            import('../views/' + item.url + '/index.vue'),
                        children: [],
                    })
                    // 二级路由
                    if (item.children && item.children.length > 0) {
                        item.children.forEach((subItem) => {
                            obj.children.push({
                                path: '/' + item.url + '/' + subItem.url,
                                component: () =>
                                    import(
                                        '../views/' +
                                            item.url +
                                            '/' +
                                            subItem.url +
                                            '.vue'
                                    ),
                            })
                        })
                    }
                })
            }
            // console.log('createRouter mutation', state.menuList, obj)
            //添加动态路由
            router.addRoute(obj)
        },
    },
    actions: {
        updateMenu({ commit }, menu) {
            console.log('updateMenu Action', menu)
            commit('updateMenu', menu)
        },
    },
}
